import React, { memo } from "react"
import { css, Theme } from "@emotion/react"
import { extractNumber, formatCurrency } from "@ncs/ts-utils"
import { Box } from "../layout"
import { SkeletonRows } from "../transitions"
import { Heading, HeadingProps } from "./Heading"
import { Paragraph } from "./Paragraph"

export interface PriceEcommProps {
	small?: boolean
	/** Provide the final price after any discounts. Will get multiplied by `quantity`.  */
	price: number | string
	/** Optionally pass in a quantity. Price will be multiplied by this. Defaults to 1. */
	quantity?: number
	/** The price before any discounts. */
	nonDiscountedPrice?: number | string
	textAlign?: "left" | "right" | "center"
	isLoading?: boolean
	color?: HeadingProps["color"]
	/**
	 * Round to the nearest integer.
	 */
	round?: boolean
	className?: string
}

export const PriceEcomm: React.FC<PriceEcommProps> = memo(
	({
		small,
		price,
		nonDiscountedPrice,
		quantity = 1,
		textAlign = "left",
		isLoading,
		color,
		round,
		className,
	}) => {
		return (
			<Box display="flex" flexDirection="column" textAlign={textAlign}>
				<Heading
					variant={small ? "h3" : "h2"}
					css={finalPriceStyle}
					className={className}
					textAlign={textAlign}
					color={color}
					bold
				>
					{isLoading ?
						<Box
							display="flex"
							alignItems="center"
							justifyContent={
								textAlign === "right" ? "flex-end"
								: textAlign === "left" ?
									"flex-start"
								: textAlign === "center" ?
									"center"
								:	undefined
							}
						>
							$
							<SkeletonRows
								rows={1}
								width={8}
								rowProps={{ my: 0, ml: 0.75, mt: -0.25 }}
							/>
						</Box>
					:	formatCurrency(extractNumber(price) * quantity, round ? 0 : undefined)}
				</Heading>

				{quantity > 1 && !!price && (
					<Paragraph small color="secondary">
						{formatCurrency(price)} each
					</Paragraph>
				)}

				{!!nonDiscountedPrice &&
					extractNumber(nonDiscountedPrice) > extractNumber(price) && (
						<div>
							<span css={nonDiscountedPriceStyle}>
								Reg {formatCurrency(nonDiscountedPrice)}
							</span>
						</div>
					)}
			</Box>
		)
	}
)

const finalPriceStyle = (theme: Theme) => css`
	color: ${theme.palette.primary.main};
`
const nonDiscountedPriceStyle = (theme: Theme) => css`
	color: ${theme.palette.error.main};
	text-decoration: line-through;
`
